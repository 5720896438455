<script>
import { mapActions, mapGetters } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';
import helper from '@/plugins/helper';
import TRANSACTIONS_STATUSES from '@/constants/transactions_statuses';

export default {
    name: 'Transactions',
    components: { UserModal },

    data: () => ({
        TRANSACTIONS_STATUSES: TRANSACTIONS_STATUSES,
        date_picker: [],
        menu: false,
        loading: false,
        loadingExcel: false,
        id_transaction: '',

        transactionType: [
            {
                name: 'All',
                id: null,
            },
            {
                name: 'Buy Shares',
                id: '1',
            },
            {
                name: 'Print Certificate',
                id: '31',
            },
        ],

        transactionStatus: [
            {
                name: 'All',
                id: null,
            },
            {
                name: 'Pending',
                id: '0',
            },
            {
                name: 'Done',
                id: '1',
            },
            {
                name: 'Declined',
                id: '2',
            },
        ],

        filter: {
            type: null,
            user_mail: null,
            amount: null,
            payment_id: null,
            quantity_lots: null,
            status: null,
        },

        date: {
            from: null,
            to: null,
        },
    }),

    methods: {
        ...mapActions('transactions', [
            'getTransactionsDataPagination',
            'getPdfTransactionDetails',
            'getPdfTransactionInvoice',
            'getAllTransactionExcel',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),

        clearSearch() {
            this.filter = {};
            this.paginationTable({ itemsPerPage: 50, page: 1 });
        },

        clickSearch() {
            this.paginationTable({ itemsPerPage: 50, page: 1 });
        },

        changeTransactionType(value) {
            this.filter.type = value;
            this.paginationTable({ itemsPerPage: 50, page: 1 });
        },

        changeTransactionStatus(value) {
            this.filter.status = value;
            this.paginationTable({ itemsPerPage: 50, page: 1 });
        },

        copyText(text) {
            try {
                navigator.clipboard.writeText(text);
                this.$alertify.success('Payment ID copied successfully');
            } catch (error) {
                this.$alertify.error('Payment ID copied error');
            }
        },

        async viewPdfDetails({ hash, status, type, id }) {
            this.id_transaction = id;

            if (status === '0' && type === 'Buy Shares') {
                await this.getPdfTransactionInvoice(
                    'invoice_number' + '=' + hash,
                ).then(res => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    window.open(link.href);
                });
            } else if (type === 'Print Certificate') {
                await this.getPdfTransactionDetails(
                    'transaction_id' + '=' + hash + '&type' + '=' + '1',
                ).then(res => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    window.open(link.href);
                });
            } else {
                await this.getPdfTransactionDetails(
                    'transaction_id' + '=' + hash,
                ).then(res => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    window.open(link.href);
                });
            }
            this.id_transaction = '';
        },

        async downloadAllTransactionExcel() {
            this.loadingExcel = true;
            
            await this.getAllTransactionExcel().then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'all-transaction.xlsx');
                document.body.appendChild(link);
                link.click();
            });
            this.loadingExcel = false;
        },

        resetDate() {
            this.date_picker = [];
            this.date = {
                from: 0,
                to: Date.now(),
            };
            this.paginationTable({ itemsPerPage: 50, page: 1 });
            this.menu = false;
        },

        changeDate() {
            const THREE_HOURS_IN_SECONDS = 3 * 3600;
            const DAY_IN_SECONDS = 86399;

            let startDateTimestamp = helper.toTimestamp(this.date_picker['0']);

            let endDateTimestamp = helper.toTimestamp(this.date_picker['1']);

            if (startDateTimestamp > endDateTimestamp) {
                [startDateTimestamp, endDateTimestamp] = [
                    endDateTimestamp,
                    startDateTimestamp,
                ];
            }

            this.date.from = startDateTimestamp - THREE_HOURS_IN_SECONDS;
            this.date.to =
                endDateTimestamp + DAY_IN_SECONDS - THREE_HOURS_IN_SECONDS;

            this.paginationTable({ itemsPerPage: 50, page: 1 });
            this.menu = false;
        },

        dataDayYear(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formatter.format(new Date(+time * 1000));
        },

        dataTime(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('ru', {
                timeStyle: 'medium',
                hour12: false,
            });
            return formatter.format(new Date(+time * 1000));
        },

        async paginationTable(event) {
            this.loading = true;
            const { itemsPerPage, page } = event;

            const payload = {
                limit: itemsPerPage,
                offset: page - 1,
                type_id: this.filter.type,
                payment_id: this.filter.payment_id,
                amount: this.filter.amount,
                email: this.filter.user_mail,
                shares_count: this.filter.quantity_lots,
                date_start: this.date.from,
                date_end: this.date.to,
                status: this.filter.status,
            };

            if (event.sortBy && event.sortDesc) {
                if (event.sortBy[0] === 'amount' && !event.sortDesc[0]) {
                    payload.sort_by_amount = 1;
                }
                if (event.sortBy[0] === 'amount' && event.sortDesc[0]) {
                    payload.sort_by_amount_desc = 1;
                }
                if (event.sortBy[0] === 'fee' && !event.sortDesc[0]) {
                    payload.sort_by_fee = 1;
                }
                if (event.sortBy[0] === 'fee' && event.sortDesc[0]) {
                    payload.sort_by_fee_desc = 1;
                }
                if (event.sortBy[0] === 'quantity_lots' && !event.sortDesc[0]) {
                    payload.sort_by_quantity_shares = 1;
                }
                if (event.sortBy[0] === 'quantity_lots' && event.sortDesc[0]) {
                    payload.sort_by_quantity_shares_desc = 1;
                }
                if (event.sortBy[0] === 'create_time' && !event.sortDesc[0]) {
                    payload.sort_by_date = 1;
                }
                if (event.sortBy[0] === 'create_time' && event.sortDesc[0]) {
                    payload.sort_by_date_desc = 1;
                }
            }

            await this.getTransactionsDataPagination(payload);
            this.loading = false;
        },

        numberWithSpaces(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },

        getColorByStatus(status) {
            let color;
            switch (parseInt(status)) {
                case TRANSACTIONS_STATUSES.PENDING:
                    color = 'orange';
                    break;
                case TRANSACTIONS_STATUSES.DONE:
                    color = 'green';
                    break;
                case TRANSACTIONS_STATUSES.DECLINE:
                    color = 'red';
                    break;
                default:
                    color = null;
                    break;
            }
            return color;
        },

        getColorByType(type) {
            let color;
            switch (type) {
                case 'Print Certificate':
                    color = 'indigo';
                    break;
                case 'Buy Shares':
                    color = 'teal';
                    break;
                default:
                    color = 'default';
                    break;
            }
            return color;
        },
    },
    computed: {
        ...mapGetters('transactions', {
            transactions: 'getTransactions',
            totalTransactions: 'getTotalTransactions',
        }),

        headers() {
            return [
                {
                    text: 'Transaction Type',
                    value: 'type',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Payment ID',
                    value: 'hash',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'User mail',
                    value: 'user_mail',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Amount (€)',
                    value: 'amount',
                    align: 'center',
                },
                {
                    text: 'Fee (%)',
                    value: 'fee',
                    align: 'center',
                },
                {
                    text: 'Discount (%)',
                    value: 'discount',
                    align: 'center',
                },
                {
                    text: 'Quantity Shares',
                    value: 'quantity_lots',
                    align: 'center',
                },
                {
                    text: 'Created time',
                    value: 'create_time',
                    align: 'center',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Details',
                    value: 'details',
                    align: 'center',
                    sortable: false,
                },
            ];
        },
    },
};
</script>

<style scoped>
.v-input.v-input--hide-details.theme--light.v-text-field.v-text-field--single-line.v-text-field--is-booted {
    margin-bottom: 22px;
}

.startup__actions {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}

.btnDownload {
    cursor: pointer;
}
</style>

<template>
    <div>
        <div class="startup__actions">
            <v-btn
                class="btnDownload"
                color="#47a4db"
                :loading="loadingExcel"
                @click="downloadAllTransactionExcel"
            >
                Download Excel
            </v-btn>
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            @update:options="paginationTable($event)"
            dense
            :headers="headers"
            :items="loading ? [] : transactions"
            :items-per-page="10"
            :footer-props="{
                'items-per-page-options': [10, 50, 100, 150, 200],
            }"
            :server-items-length="totalTransactions"
        >
            <template v-slot:[`item.hash`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            @click="copyText(item.hash)"
                            v-on="on"
                            v-bind="attrs"
                            style="cursor: pointer"
                            >{{ item.hash.substr(0, 10) }}</span
                        >
                    </template>
                    <span>{{ item.hash }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.user_mail`]="{ item }">
                <v-tooltip v-if="item.type === 'Buy Shares'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.user_mail }}</a
                        >
                    </template>
                    <span>User id {{ item.user_id }}</span>
                </v-tooltip>
                <v-tooltip v-if="item.type === 'Print Certificate'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_account_id)"
                            >{{ item.user_mail }}</a
                        >
                    </template>
                    <span>User id {{ item.user_account_id }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
                {{ numberWithSpaces(item.amount) }}
            </template>

            <template v-slot:[`item.fee`]="{ item }">
                {{
                    item.fee
                        ? `${item.fee} € (${
                              item.fee_percentage ? item.fee_percentage : '0'
                          }%)`
                        : '-'
                }}
            </template>

            <template v-slot:[`item.discount`]="{ item }">
                {{
                    item.discount
                        ? `${item.discount} € (${item.discount_percentage}%)`
                        : '-'
                }}
            </template>

            <template v-slot:[`item.quantity_lots`]="{ item }">
                {{
                    item.quantity_lots
                        ? numberWithSpaces(item.quantity_lots)
                        : '-'
                }}
            </template>

            <template v-slot:[`item.create_time`]="{ item }">
                <span style="margin-right: 10px">
                    {{ dataDayYear(item.create_time) }}
                </span>
                {{ dataTime(item.create_time) }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-tooltip
                    bottom
                    v-if="
                        parseInt(item.status) === TRANSACTIONS_STATUSES.PENDING
                    "
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Pending
                        </v-chip>
                    </template>
                    <span>Pending status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="parseInt(item.status) === TRANSACTIONS_STATUSES.DONE"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Done
                        </v-chip>
                    </template>
                    <span>Done status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        parseInt(item.status) === TRANSACTIONS_STATUSES.DECLINE
                    "
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Declined
                        </v-chip>
                    </template>
                    <span>Declined status</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.type`]="{ item }">
                <v-tooltip
                    bottom
                    v-if="item.type === 'Print Certificate'"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByType(item.type)"
                        >
                            {{ item.type }}
                        </v-chip>
                    </template>
                    <span> {{ item.type }} type</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="item.type !== 'Print Certificate'"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByType(item.type)"
                        >
                            {{ item.type }}
                        </v-chip>
                    </template>
                    <span> {{ item.type }} type</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.details`]="{ item }">
                <div style="display: flex; justify-content: center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                text
                                :loading="item.id === id_transaction"
                                @click="viewPdfDetails(item)"
                                style="height: 24px"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span>View PDF details?</span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-select
                            @change="changeTransactionType"
                            :items="transactionType"
                            type="text"
                            v-model="filter.type"
                            item-value="id"
                            item-text="name"
                        ></v-select>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.payment_id"
                            label="Search"
                            single-line
                            hide-details
                            @click:append="clickSearch"
                            @click:clear="clearSearch"
                            clearable
                            append-icon="mdi-check"
                        >
                        </v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.user_mail"
                            label="Search"
                            single-line
                            hide-details
                            @click:append="clickSearch"
                            @click:clear="clearSearch"
                            clearable
                            append-icon="mdi-check"
                        >
                        </v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.amount"
                            label="Search"
                            single-line
                            hide-details
                            @click:append="clickSearch"
                            @click:clear="clearSearch"
                            clearable
                            append-icon="mdi-check"
                        >
                        </v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <v-text-field
                            v-model="filter.quantity_lots"
                            label="Search"
                            single-line
                            hide-details
                            @click:append="clickSearch"
                            @click:clear="clearSearch"
                            clearable
                            append-icon="mdi-check"
                        >
                        </v-text-field>
                    </td>
                    <td>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date_picker"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date_picker" no-title range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resetDate">
                                    Clear
                                </v-btn>
                                <v-btn text color="primary" @click="changeDate">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>
                    <td>
                        <v-select
                            @change="changeTransactionStatus"
                            :items="transactionStatus"
                            type="text"
                            v-model="filter.status"
                            item-value="id"
                            item-text="name"
                        ></v-select>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <user-modal />
    </div>
</template>
